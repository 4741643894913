<template>
  <div>
    <el-dialog
      title="报名信息"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      width="1000px"
    >
      <!-- 内层div -->

      <el-dialog
        width="50%"
        :visible.sync="innerVisible"
        append-to-body
        title="添加报名项目"
      >
        <InnerTablePage
          :data="tableData"
          :columns="columnsInner"
          :border="true"
          @handSelect="handSelect"
          @handSelectAll="handSelectAll"
        >
          <template slot="quantity" slot-scope="scope">
            <el-input
              placeholder="报名数量"
              type="number"
              min="1"
              oninput=""
              @input="
                scope.row.quantity =scope.row.quantity.replace(/[^\d]/g, '')
              "
              v-model="scope.row.quantity"
            >
            </el-input>
          </template>
        </InnerTablePage>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="Add()">提 交</el-button>
          <el-button type="primary" @click="innerVisible = false"
            >取 消</el-button
          >
        </span>
      </el-dialog>
      <el-form
        ref="form"
        :inline="true"
        :model="form"
        label-width="100px"
        :rules="formRules"
      >
        <table border="1">
          <tr>
            <td>报名人</td>
            <td>{{ this.SignUp }}</td>
          </tr>
          <tr>
            <td>配送村镇</td>
            <td>
              <!-- <el-cascader
                ref="cascader"
                v-model="form.areaCode"
                :props="props"
                :options="regionOptions"
                :show-all-levels="true"
              ></el-cascader> -->
              <el-cascader
                ref="address"
                v-model="form.areaCode"
                :options="regionOptions"
                :props="prop"
                style="width: 100%"
                @change="handleChange"
              ></el-cascader>
            </td>
          </tr>
        </table>
        <table border="1">
          <tr>
            <td>
              <p>报名项目</p>
              <el-button type="primary" @click="AddBtn">添加</el-button>
            </td>
            <td>
              <table-page
                :data="tableDataList"
                :columns="columns"
                :border="true"
              >
                <template slot="model" slot-scope="scope">
                  {{
                    scope.row.model +
                    scope.row.agriculturalType +
                    "(" +
                    scope.row.guidePrice +
                    "元" +
                    "/" +
                    scope.row.unit +
                    ")"
                  }}
                </template>

                <template slot="guidePrice" slot-scope="scope">
                  {{ scope.row.guidePrice + "(元)" + "/" + scope.row.unit }}
                </template>

                <template slot="quantity" slot-scope="scope">
                  <el-input
                    placeholder="报名数量"
                    type="number"
                    style="width: 90px"
                    min="1"
                    @input="
                      scope.row.quantity = scope.row.quantity.replace(
                        /[^\d]/g,
                        ''
                      )
                    "
                    v-model="scope.row.quantity"
                  >
                  </el-input>
                  {{ " *" + scope.row.unit }}
                </template>
                <template slot="totalPrice" slot-scope="scope">
                  {{ scope.row.quantity * scope.row.guidePrice }}
                </template>
              </table-page>
            </td>
          </tr>
          <tr>
            <td>备注</td>
            <td>
              <el-input
                placeholder="备注仅本人可见"
                min="1"
                v-model="form.notes"
              >
              </el-input>
            </td>
          </tr>
        </table>
        <div class="Price">
          <table border="1">
            <tr>
              <td>总价:</td>
              <td>{{ this.totalPrice }}元</td>
            </tr>
            <tr>
              <td>预付费:</td>
              <td>{{ this.prepayment }}元</td>
            </tr>
          </table>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="applyProcurement()"
          :disabled="is_click"
          >提交</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
// import { AreaTree } from "@/api/RuralFinance";
import { getAreaTree } from "@/api/certificate";
import { removeEmptyChildren } from "@/js/utils/util";
import { mapGetters } from "vuex";
import TablePage from "@/components/table/CallForBids-page";
import { applyProcurement } from "@/api/CollectivePurchase";
import InnerTablePage from "@/components/table/CollectivePurchase-table";
import {
  RegistrationInformation,
  InnerTableList,
} from "../memberCenter/CollectionMining/tableColumn";
export default {
  name: "NowSignUp",

  components: {
    TablePage,
    InnerTablePage,
  },
  data() {
    return {
      dialogVisible: true,
      columns: RegistrationInformation,
      columnsInner: InnerTableList,
      tableData: [],
      props: {
        label: "areaName",
        children: "children",
        value: "id",
        fullPathName: "fullPathName",
        checkStrictly: true,
        emitPath: false,
      },
      form: {
        areaCode: "",
        notes: "",
      },
      formRules: {},
      innerVisible: false,
      regionOptions: [], //地区
      SignUp: "",
      procurementId: "",
      tableDataList: [],
      ConfigurationList: [], //这是选择完的信息
      paymentRatio: "",
      prop: {
        label: "areaName",
        children: "children",
        value: "id",
        fullPathName: "fullPathName",
        checkStrictly: true,
        emitPath: false,
      },
      is_click: false, //防止暴力点击
    };
  },
  computed: {
    totalPrice() {
      let total = 0;
      for (let i = 0; i < this.tableDataList.length; i++) {
        total +=
          this.tableDataList[i].quantity * this.tableDataList[i].guidePrice;
      }
      return total;
    },
    //预付费
    prepayment() {
      let total = 0;
      for (let i = 0; i < this.tableDataList.length; i++) {
        total +=
          this.tableDataList[i].quantity * this.tableDataList[i].guidePrice;
      }
      let PayMoney = total * (this.paymentRatio / 100);
      return PayMoney.toFixed(2);
    },
    ...mapGetters(["identityObj"]),
  },
  mounted() {
    this.AreaTree();
  },

  methods: {
    back() {
      this.$emit("closeDialog", false);
    },
    handleClose() {
      this.$emit("closeDialog", false);
    },
    //添加新品种
    async AddBtn() {
      // let params = {};
      this.innerVisible = true;
    },
    //所属区域
    async AreaTree() {
      // let params = {
      //   parentCode: "",
      // };
      // let res = await AreaTree(params);
      // this.this.regionOptions =this.identityObj

      let param = {
        level: "5",
      };
      this.form.areaCode = this.identityObj.areaCode;
      let res = await getAreaTree(param);
      // console.log("行政区",res);
      let { code, data, msg } = res;
      if (code == 0) {
        this.regionOptions = removeEmptyChildren(data);
      } else {
        this.$message.error(msg);
      }

      // this.regionOptions = res.data;
      //报名人
      this.SignUp = this.identityObj.identityName;
    },
    // initSignUp(obj) {
    //   console.log(obj,'查看obj');
    // },
    //提交报名
    async applyProcurement() {
      this.is_click = true;
      let params = {
        address: this.form.areaCode,
        notes: this.form.notes,
        applyProcurementList: this.tableDataList,
        procurementId: this.procurementId,
        prepayment: this.prepayment,
      };

      let res = await applyProcurement(params);
      if (res.code == 0) {
        this.$message.success(res.msg);
      } else {
        this.$message.info(res.msg);
      }
      this.$emit("closeDialog", false);
      // console.log(params, "查看params");
    },
    initSignUp(obj) {
      this.paymentRatio = obj.paymentRatio;
      this.tableData = obj.newAgriculturalVoList;
      this.procurementId = obj.procurementId;
    },
    // 全选或者反选
    handSelectAll(selection) {
      this.ConfigurationList = selection;
      console.log(selection, "全选");
    },
    //多选
    handSelect(selection) {
      this.ConfigurationList = selection;
      console.log(selection, "多选");
    },
    // 添加所选信息
    Add() {
      this.tableDataList = this.ConfigurationList;
      console.log(this.tableDataList, "this.tableDataList");
      this.innerVisible = false;
    },
    // 获取项目地址
    handleChange() {
      let dz = this.$refs["address"].getCheckedNodes()[0].pathLabels.join("/");
      this.form.areaCode = dz;
    },
  },
};
</script>

<style lang="less" scoped>
table {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  tr {
    td {
      /deep/.el-cascader {
        width: 100%;
        .el-input__inner {
          text-align: center;
        }
      }
    }
  }
}
.Price {
  width: 100%;
  height: 50px;
  margin-top: 20px;
  table {
    width: 30%;
    float: right;
  }
}
</style>
